@import url(https://fonts.googleapis.com/css?family=Mulish);
@import url(https://fonts.googleapis.com/css?family=Exo+2);
@import url(https://fonts.googleapis.com/css2?family=Material+Icons);
html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "Mulish", sans-serif;
  background: linear-gradient(180deg, rgba(238, 174, 202, 0.2) 0%, rgba(148, 187, 233, 0.2) 100%);
}

h2 {
  font-family: "Exo 2", sans-serif;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f6f6f6;
}

@media only screen and (min-width: 992px) {
  .lang-dropdown {
    position: absolute;
    right: 1em;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #9C27B0;
}

.elle-dark-text {
  color: #9C27B0;
}

.elle-medium-text {
  color: #CCA8FD;
}

.tooltip-icon {
  margin-left: 0.25em;
  color: #3B3B3B;
  border: 2.5px solid #3B3B3B;
  border-radius: 12px;
  padding: 1px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #9C27B0 !important;
}

.MuiInputLabel-root.Mui-focused,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-root.Mui-checked {
  color: #9C27B0 !important;
}


.MuiMenuItem-root.Mui-selected {
  background-color: rgba(255, 208, 253, .5) !important;
}

.MuiAlert-standardInfo {
  background-color: rgba(255, 208, 253, .5) !important;
}

.MuiAlert-standardInfo .MuiAlert-icon {
  color: #9C27B0 !important;
}

@media (max-width: 925px) {
  .navBar-icon-button {
    display: flex !important;
  }

  .nav-menu-link-container {
    display: none;
  }

  .language-menu-desktop {
    display: none;
  }

  .nav-logout.desktop {
    display: none !important;
  }

  .elle-nav-logo {
    height: 30px !important;
  }
}

@media (min-width: 926px) {
  .navBar-icon-button {
    display: none !important;
  }

  .navbar-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .nav-menu-link-container {
    display: flex;
  }

  .language-menu-desktop {
    display: flex;
  }

  .elle-nav-logo {
    height: 35px !important;
  }
}

@media (max-width: 1334px) {
  .navbar-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1063px) {
  .nav-logout.desktop {
    .logout-text {
      display: none;
    }
  }

  .nav-menu-item {
    margin-left: 0 !important;
  }

  .nav-icons-container {
    width: 5% !important;
  }
}

.language-menu-desktop {
  align-items: center;
  justify-content: end;
}

.language-icon {
  color: black;
  cursor: pointer;
}

.nav-drawer-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.nav-logo-container {
  width: 5%;
}

.nav-50px-height {
  height: 50px !important;
}

.nav-menu-link-container {
  padding-left: 5%;
  width: 85%;
  justify-content: center;
  align-items: center;
}

.nav-icons-container {
  width: 17.5%;
  display: flex;
  justify-content: flex-end;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.burger-menu-icon {
  color: black;
  font-size: 45px !important;
}

.hover:hover {
  cursor: pointer;
}

.lang-icon {
  padding-right: 0.5em;
}

.sticking,
.not-sticking {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
}

.sticking {
  background: transparent !important;
}

.not-sticking {
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 9%, rgba(255, 208, 253, 1) 9%, rgba(255, 208, 253, 1) 22%, rgba(255, 255, 255, 1) 22%, rgba(255, 255, 255, 1) 100%);
  box-shadow: 0 0 5px 0 rgba(156, 39, 176, 0.5) !important;
}

.nav-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  line-height: 100%;
  text-align: center;
}

.nav-menu-item::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 10%;
  width: 100%;
  background-color: #9C27B0 !important;
  -webkit-transform-origin: right;
          transform-origin: right;
  transition: 0.3s ease-in-out;
  scale: 0 1;
}

.nav-menu-item:hover::after {
  scale: 1 1;
}

.nav-close-icon {
  color: black !important;
  font-size: 45px !important;
}

.nav-logout {
  font-weight: 600;
  color: #1B1B1B;
  font-family: "Exo 2", sans-serif;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #9C27B0;
    cursor: pointer;
  }

  &:active {
    color: #9C27B0;
    cursor: pointer;
  }

  .logout-text {
    padding-left: 0.5rem;
  }
}

.success-alert {
  max-width: 35em;
}

.error-alert {
  background-color: #d32f2f !important;
  color: white !important;
  max-width: 35em;
}

.error-alert svg {
  color: white;
}

@-webkit-keyframes slidein {
  from {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes Overlay-slide {
  from {
    -webkit-transform: translate(80%, -30%) rotate(20deg);
            transform: translate(80%, -30%) rotate(20deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(40%, -30%) rotate(20deg);
            transform: translate(40%, -30%) rotate(20deg);
    opacity: 1;
  }
}

@keyframes Overlay-slide {
  from {
    -webkit-transform: translate(80%, -30%) rotate(20deg);
            transform: translate(80%, -30%) rotate(20deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(40%, -30%) rotate(20deg);
            transform: translate(40%, -30%) rotate(20deg);
    opacity: 1;
  }
}

@-webkit-keyframes image-card-slide {
  from {
    -webkit-transform: translate(100px);
            transform: translate(100px);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0px);
            transform: translate(0px);
    opacity: 1;
  }
}

@keyframes image-card-slide {
  from {
    -webkit-transform: translate(100px);
            transform: translate(100px);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0px);
            transform: translate(0px);
    opacity: 1;
  }
}

.home-title {
  text-align: center;
  margin-left: 20%;
  margin-bottom: 5%;
  padding-top: 4%;
  width: 60%;
}

.home-mockup-card {
  margin-left: 10%;
  margin-right: 10%;
}

.home-mockup-card-box {
  display: flex;
  width: 100%;
}

.home-mockup-card-textbox {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
}

.home-mockup-card-typography {
  padding: 10%;
  font-size: 1.3rem !important;
  text-align: center;
}

.home-summary-card {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 50px;
}

.home-summary-card-title-box {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.home-summary-card-title-typography {
  font-size: 30px !important;
  font-weight: bold;
}

.home-summary-card-content-box {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  width: 100%;
  padding: 40px;
}

.home-summary-card-content-box-inner-1 {
  height: auto;
  width: 25%;
}

.home-summary-card-content-box-inner-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.home-summary-card-avatar-box {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  width: 100%;
}

.home-summary-card-avatar {
  background-color: #9C27B0 !important;
}

.home-summary-card-content-textbox {
  display: flex;
  width: 100%;
}

.home-summary-card-content-typography {
  align-self: center;
  text-align: center;
}

.home-tabs-card {
  margin: 50px 10% 0;
}

.tab-button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 50px;
  font-weight: bold;
}

.picture-box {
  overflow: hidden;
  position: relative;
  border: solid 3px;
  border-top: none;
  width: 100%;
  border-radius: 5px;
}

.image-class {
  height: 800px;
  padding: 10px;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: slidein;
          animation-name: slidein;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

.image-container {
  overflow: hidden;
}

.image-card {
  outline: solid 5px;
  background: white;
  position: absolute;
  border-radius: 5px;
  top: 40%;
  right: 5%;
  width: 40%;
  height: 30%;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-name: image-card-slide;
          animation-name: image-card-slide;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

.overlay {
  height: 1600px;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: translate(40%, -30%) rotate(20deg);
          transform: translate(40%, -30%) rotate(20deg);
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: Overlay-slide;
          animation-name: Overlay-slide;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}



.hero-container {
  position: relative;
  display: flex;
  height: 35vw;
  margin-top: 150px;
  border-radius: 50px;
  background: rgb(204, 168, 253);
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 21%, rgba(255, 208, 253, 1) 21%, rgba(255, 208, 253, 1) 44%, rgba(255, 255, 255, 1) 44%, rgba(255, 255, 255, 1) 100%);
}

.hero-text-container {
  margin-left: 10%;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 2vw;
}

.hero-image-box {
  border-radius: 0 0 100px 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  width: 60%;
  height: 120%;
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.hero-container::after {
  position: absolute;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  border-radius: 50px;
  content: " ";
  height: 100%;
  width: 100%;
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 21%, rgba(255, 208, 253, 0) 21%, rgba(255, 208, 253, 0) 44%, rgba(255, 255, 255, 0) 44%, rgba(255, 255, 255, 0) 100%);
}

.hero-img {
  width: 100%;
  object-fit: cover;
}

.button-design {
  z-index: 1;
}


.services-container {
    width: 100%;
    height: 300px;
    display: flex;
    grid-gap: 50px;
    gap: 50px;
}

.service-detail {
    border-radius: 10px;
}

.service-container-outer {
    border-radius: 50px;
    margin-top: 100px;
    text-align: center;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(180deg, rgba(255, 208, 253, 0.7) 0%, rgba(255, 255, 255, 0) 50%);
}

.service-title-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 200px;
    background-color: rgb(255, 208, 253);
}

.service-text {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10% 10% 0;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(0deg, rgba(252, 245, 255, 1) 0%, rgba(255, 255, 255, 1) 50%);
    border-radius: 5px;
}

.service-detail:first-child {
    border-radius: 50px 10px 10px 50px;
}

.service-detail:first-child .service-text {
    border-radius: 45px 5px 5px 45px;
}

.service-detail:last-child {
    border-radius: 10px 50px 50px 10px;
}

.service-detail:last-child .service-text {
    border-radius: 5px 45px 45px 5px;
}
@-webkit-keyframes bg-slide-in-info {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

@keyframes bg-slide-in-info {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

.info-element-container {
  margin-top: 100px;
  height: 700px !important;
  border-radius: 50px;
}

.info-element-inner {
  width: 100%;
  height: 100%;
  border-radius: 45px;
  position: relative;
  overflow: hidden;
  display: flex;
}

.info-element-inner::after {
  height: 15%;
  width: 31%;
  left: -10.5%;
  top: 0;
  background-color: #FFD0FD;
  position: absolute;
  content: "";
  -webkit-transform: skew(-45deg);
          transform: skew(-45deg);
  z-index: 2;
}

.info-tool-title {
  display: flex;
  padding: 1%;
  align-items: center;
  justify-content: center;
  height: 15%;
  width: 20%;
  background-color: transparent;
  position: absolute;
  z-index: 3;
}

.elle-tools {
  width: 17%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.btn-visible:nth-child(7)::after {
  border-radius: 0 5px 0 0;
}

.info-video {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 83%;
  height: 100%;
}

.info-video-styling {
  position: relative;
  width: 100%;
}

.info-video::after {
  content: "";
  top: 0;
  width: 85%;
  height: 100%;
  background-color: #0F0411;
  opacity: 5%;
  position: absolute;
}

.btn-visible:nth-child(1) .info-box-slide-visible {
  top: 5%;
}

.btn-visible:nth-child(7) .info-box-slide-visible {
  bottom: 5%;
}

.video-overlay {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.play-button-info {
  scale: 7;
  color: rgb(204, 168, 253);
  outline: 2px rgb(204, 168, 253) solid;
  border-radius: 100px;
  background-color: rgb(15, 14, 17, 0.5);
  transition: 0.3s ease-in-out;
}

.play-button-info:hover {
  cursor: pointer;
  scale: 8
}

.tools-video-text {
  font-size: 2em !important;
  top: 20%;
}




.btn-visible,
.btn-invisible {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12.15%;
  width: 100%;
  transition: 0.3s ease-in-out;
  background-color: #ffebfe !important;
  z-index: 102;
}

.btn-invisible {
  z-index: 2;
}

.btn-visible::after,
.btn-invisible::after {
  content: "";
  border-radius: 0 5px 5px 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 110%;
  position: absolute;
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 8%, rgba(255, 208, 253, 1) 8%, rgba(255, 208, 253, 1) 88%);
  transition: 0.3s ease-in-out;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
  z-index: 103;
}

.btn-invisible::after {
  left: -100%;
  width: 100%;
  box-shadow: none;
}

.btn-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  box-shadow: 0px 0px 0px 5px rgba(255, 208, 253, 1) inset;
}

.btn-box-inner::after {
  content: "";
  position: absolute;
  left: 80%;
  top: 80%;
  height: 75%;
  -webkit-transform-origin: center;
          transform-origin: center;
  width: 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #9C27B0;
  outline: solid 15px #FFD0FD;
  box-shadow: 0px 0px 0px 15px rgb(204, 168, 253) inset;
}

.info-box-slide-visible,
.info-box-slide-invisible {
  border-radius: 10px;
  height: 200px;
  left: 120%;
  width: 400px;
  position: absolute;
  opacity: 1;
  transition: opacity 0.3s;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

.info-box-slide-invisible {
  display: none;
  opacity: 0 !important;
  transition: 0.2s;
}

.tool-button-text {
  padding-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 104;
}

.info-tab-helper-strip {
  height: 100%;
  position: absolute;
  left: 100%;
}

.statistics-container {
  display: grid;
  width: 100%;
  height: 300px;
  margin-top: 100px;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 50px;
  gap: 50px;
}

.statistics-inner-container {
  border-radius: 10px 10px 10px 10px;
}

.statistics-inner-container:last-child {
  border-radius: 10px 50px 50px 10px;
}

.statistics-inner-container:last-child div {
  border-radius: 5px 45px 45px 5px;
  padding: 10%;
}

.statistics-inner-container:first-child {
  border-radius: 50px 10px 10px 50px;
}

.statistics-inner-container:first-child div {
  border-radius: 45px 5px 5px 45px;
}

.statistics-box-inner {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.statistics-box-inner p {
  font-size: 20px;
}

.statistics-icon {
  font-size: 50px !important;
  color: #9C27B0;
  margin-bottom: 25px;
}

.linkImage {
    width: 100px;
    height: 100px;
    margin-left: 12%;
    margin-top: 5%;
    border-radius: 10px;
}

.links-container {
  height: auto;
  display: flex;
  align-items: center;
  background: fixed linear-gradient(315deg, rgba(156, 39, 176, 1) 0%, rgba(156, 39, 176, 1) 33%, rgba(204, 168, 253, 1) 33%, rgba(204, 168, 253, 1) 67%, rgba(255, 208, 253, 1) 67%, rgba(255, 208, 253, 1) 100%);
  justify-content: center;
  border-radius: 50px;
  flex-direction: column;
  padding: 7px;
  margin-bottom: 50px;
}

.link-anchor-gap {
  height: 70px;
}

.links-treeview-container {
  display: flex;
  background-color: white;
  border-radius: 45px;
  height: auto;
}

.links-container-sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 100%;
  width: 25%;
  margin-top: 20px;
  top: 50px;
}

.links-tree-view-right-border {
  width: 100%;
  height: auto;
  margin-left: 20px;
  border-right: solid 1px #FFD0FD;
  margin-top: 100px;
}

.link-expand-icon {
  padding: 6px;
  font-size: 2rem !important;
}

.treeitem-label {
  font-weight: 600;
  font-size: 16px;
  color: #1B1B1B;
  text-decoration: none;
  margin: 0.25rem !important;
}

.treeitem-label:hover,
.treeitem-label:active {
  color: #9C27B0;
  text-decoration: none;
}

.link-container-right {
  padding: 0 50px 50px;
  width: 75%;
  height: auto;
  margin-bottom: 50px;
}

.contact-box {
  display: flex;
  flex-direction: row;
  width: 700px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.icon {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 10px;
  font-size: large;
}

.text-box {
  width: 70%;
  height: 50%;
}

.name-box {
  height: 35%;
  width: 100%;
  padding-left: 20px;
  font-weight: bold;
  font-size: 1.5rem;
  padding-top: 6%;
}

.info-box {
  padding-left: 20px;
  padding-top: 5%;
}

.etis-container {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.etis-link {
  color: inherit;
}


.contact-image {
  width: 200px;
  border-radius: 10px;
  margin-right: 20px;
}

.icon-box {
  width: 30%;
  height: 100%;
}

.MuiListItemButton-root {
  padding-left: 0 !important;
}

.textInputContainer {
  display: block;
  margin-bottom: 10px;
}

.textInput {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: solid 1px black;
  resize: none;
  overflow-y: auto;
}

.textInputDiv {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  border: solid 1px black;
  padding: 10px;
  margin-bottom: 10px;
  cursor: default;
}

.wordHighlightButtons {
  position: relative;
  left: 85%;
  height: 0;
  display: flex;
}

.analyseBtn {
  margin-left: 50px;
}

.lemmaTable {
  width: 500px;
  margin-top: 20px;
  border-collapse: collapse;
}

.lemmaTable th, td {
  padding: 5px 15px;
}

.lemmaTable td:nth-of-type(3) {
  text-align: center;
}

.lemmaTable td:nth-of-type(4) {
  text-align: center;
}

.marked {
  background-color: #FFFF00;
}

.blue {
  background-color: #02a5de;
}

.word {
  cursor: pointer;
  white-space: nowrap;
}

.wordInfo {
  line-height: 1em;
  max-width: 80%;
}

.textTooLongInfobox {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.filterDiv {
  position: fixed;
  width: 180px;
  left: 20%;
  top: 100px;
  z-index: 1;
  background-color: white;
}

.analyserTable {
  margin-right: auto;
  margin-left: auto;
  border-bottom: solid 1px;
  width: 100%
}

.filter-class {
  width: 330px;
  min-width: 330px
}

.accordion-class {
  border: none;
  box-shadow: none;
  width: 68px;
  height: 48px
}

.accordion-button {
  height: 48px;
  width: 68px
}

.tableRow {
  background-color: white;
}

.tableRow:nth-child(even) {
  background-color: #EBECF0;
}

.tableHead {
  padding-left: 10px;
  border-bottom: solid 1px !important;
  color: black;
  font-weight: bold
}

.popover-box {
  padding: 10px;
}

.filter-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.tableHead:hover {
  cursor: auto;
}

.tableHead:nth-child(2) > .sortIcon {
  display: none;
}

.tableHead:nth-child(3) > .sortIcon {
  display: none;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
    align-items: baseline;
}

.pagination-button-group {
    display: flex;
    border-radius: 15px !important;
}

.fontStyle {
    font-size: large;
}

.pagination-textarea {
    width: 100px;
}

.buttongroup {
    width: 25%;
}

.table-pagination-button {
    border-right: solid 1px white !important;
}

.download-license-and-button {
  padding-top: 1em;
  display: flex;
  justify-content: center;
}

.download-dialog {
  width: 300px;
  height: 170px;
}

.download-dialog-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.csvLink {
  color: white;
  text-decoration: none;
}

.csvLink:hover {
  color: white;
  text-decoration: none;
}

.table-header-button-row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.analyserTable {
  border-collapse: collapse;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-bottom: solid 1px;
}

.filter-class {
  width: 330px;
  min-width: 330px
}

.popover-box {
  padding: 10px;
}

.analyserTable td, .analyserTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.analyserTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.analyserTable tr:hover {
  background-color: #ddd;
}

.analyserTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.filter {
  position: fixed;
  top: 90px;
  left: 100px;
  width: 260px;
  height: 10px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.inner-div {
  position: relative;
  width: 100%;
  background-color: #ddd;
}

.inner-div .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ddd;
  border-color: #ddd;
}

.tableHdr {
  border-bottom: 1px solid;
  color: black;
  font-weight: bold
}

.tableHdr:nth-child(2) > .sort {
  display: none;
}

.tableHdr:nth-child(3) > .sort {
  display: none;
}

.tableHdr.headerbox:hover {
  cursor: auto;
}


.tableRow:nth-child(even) {
  background-color: #f2f2f2;
}

.tableRow:hover {
  background-color: #ddd;
}

.fontStyle {
  font-size: large;
}

.tableHeader:hover {
  cursor: auto;
}

.tableHeader:nth-child(2) > .sort {
  display: none;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  align-items: center;
}

.alertBox {
  padding: 0 10px;
  width: 65%;
  border-radius: 5px;
  background-color: #E1F5FE;
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
}

.wordAnalyserBox {
  margin-bottom: -3rem;
}

.breadcrumb-box {
  display: flex;
  width: 80vw;
  padding: 25px;
  align-items: flex-end;
}

.breadcrumb-menu-link {
  padding-right: 10px;
}

.breadcrumb-menu-link.regular {
  padding-left: 10px;
}

.popup {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: absolute;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  height: 400px;
  border-radius: 1em;
  background-color: #FFF;
}

#close {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

#file_name {
  overflow-y: auto;
}

.button-file {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 0 0 1rem -.25rem
}

.file-name-element {
  height: 150px;
  width: 500px;
  text-align: center;
}

.modal-head {
  font-size: 1.5em;
}

.close-button {
  position: absolute !important;
  top: 0.5em;
  right: 0.5em;
}

.terms-of-service-modal {
  max-width: 30em !important;
}

.text-upload-modal {
  width: 40em !important;
  height: 28em !important;
}

.wordcloud-modal {
  width: 84em !important;
}

.graph-modal {
  width: 84em !important;
}

.add-container {
  padding-right: 50px;
  padding-left: 50px;
  width: 100%;
  max-width: 100%;
  background-color: white;
  border-radius: 45px;
}

.form-control {
  width: 100%;
  margin-top: 20px !important;
  min-width: 240px;
  max-width: 270px;
}

.adding-rounded-corners {
  border-radius: 50px;
  margin-bottom: 100px;
}

.elle-outer-border {
  padding: 7px;
  background: linear-gradient(315deg, rgba(156, 39, 176, 1) 0%, rgba(156, 39, 176, 1) 33%, rgba(204, 168, 253, 1) 33%, rgba(204, 168, 253, 1) 67%, rgba(255, 208, 253, 1) 67%, rgba(255, 208, 253, 1) 100%);
  border-radius: 50px;
  margin-bottom: 100px;
}

.adding-title-text {
  width: 100%;
  text-align: center;
  padding-top: 30px;
}

.adding-text-upload-component {
  display: grid;
  justify-content: end;
  padding: 0;
  margin-top: 10em;

  & svg {
    margin: 0;
  }
}

.wordlist-analyse-button {
  margin-top: 3vh !important;
}

.wordlist-table {
  border-collapse: collapse;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.wordlist-table td, .wordlist-table th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1%;
  white-space: nowrap;
}

.wordlist-table td:last-child, .wordlist-table th:last-child {
  background-color: white;
  border: none !important;
  text-align: start;
}

.wordlist-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.wordlist-table tr:hover {
  background-color: #ddd;
}

.wordlist-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  text-align: center;
  background-color: #ffffff;
  border-bottom: solid 1px !important;
  color: black;
  font-weight: bold;
}

.wordcloud-loader-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  :nth-child(2) {
    margin-left: 1rem;
  }
}

.image-download-button-row {
  position: absolute;
  top: 2.5em;
  right: 4em;
}

.wordcontext-analyse-button {
  margin-top: 7vh !important;
}

.wordcontext-display-explanation {
  margin-left: 0.5vw !important;
  margin-top: 7px !important;
}

.wordcontext-display-count-textfield {
  width: 70px;
  margin-right: 0.5vw !important;
}

.wordcontext-table {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}

.wordcontext-table td {
  padding: 8px;
}

.wordcontext-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.wordcontext-table tr:hover {
  background-color: #ddd;
}

.wordcontext-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  text-align: center;
  background-color: #ffffff;
  border-bottom: solid 1px !important;
  color: black;
  font-weight: bold;
}

.wordcontext-table th:nth-child(3) {
  min-width: 9em;
}

.wordcontext-keyword {
  text-align: center;
  color: red;
}

.collocates-analyse-button {
  margin-top: 7vh !important;
}

.collocates-search-count-textfield {
  width: 70px;
  margin-right: 0 !important;
}

.collocates-explanation {
  margin-left: 0.5vw !important;
  margin-top: 7px !important;
}

.input-grid-item {
  padding-bottom: 10px;
}


.queryContainer {
  display: flex;
}

.queryContainer > div {
  flex: 1 1;
}

.corpustitle {
  position: relative;
  border-bottom: 1px dotted black;
}

.queryAccordion {
  margin-top: 1em !important;
  border-radius: 4px 4px 0 0 !important;
}

.buttonBox {
  background-color: black;
  top: 50px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  width: auto;
  max-width: 0;
  margin-left: 50px;
}

.buttonRight {
  margin-left: auto !important;
}

.buttonSecondLeft {
  margin-left: 1em !important;
}

.query-textinput {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: solid 1px black;
  resize: none;
  overflow-y: auto;
}

.selected-text-chip {
  margin-left: 0.5em !important;
}

.query-own-texts-modal {
  width: 700px !important;
  height: 375px !important;
  background-color: white;
  border: solid #9C27B0 4px;
  border-radius: 25px;
  padding: 20px;
  overflow: hidden;
  transition: 0.2s;
}

.query-own-texts-modal-closed {
  width: 0 !important;
  height: 0 !important;
  color: white;
  overflow: hidden;
  background-color: white;
  transition: 0.2s;
}

.button-text-query {
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  position: absolute;
  text-align: left;
  display: flex;
  align-items: center;
}

.manage-search-icon {
  font-size: 40px !important;
  margin-left: 15px !important;
  margin-right: 10px !important;
}

.manage-search-icon-text {
  font-size: 15px !important;
  font-weight: bold !important;
}

.button-query-hover {
  width: 50px !important;
  height: 60px !important;
  border-radius: 25px !important;
  overflow: hidden;
  background-color: #9C27B0 !important;
  transition: 0.3s !important;
}

.button-query-hover:hover,
.button-query-hover:focus {
  width: 210px !important;
  background-color: #AA2AC1 !important;
  transition: 0.3s;
}

.elle-dark {
  background-color: #9C27B0 !important;
}

.button-box-open {
  width: 210px !important;
}

.menu-box-choose-text {
  height: auto;
  width: 1000px;
  padding: 20px;
  margin-top: 10px;
  border: solid #9C27B0 4px;
  overflow: hidden;
  border-radius: 25px;
  background-color: white;
  transition: 0.2s;
}

.menu-box-choose-text-closed {
  position: relative;
  height: 0;
  width: 0;
  overflow: hidden;
  border-radius: 25px;
  background-color: white;
  color: white;
  transition: 0.2s;
}

.filter-box-size {
  height: 100%;
  width: 80%;
  border: solid #9C27B0 4px;
  overflow: hidden;
  border-radius: 25px;
  background-color: white;
}

.query-find-texts-icon {
  font-size: 40px !important;
  margin-left: 10px;
  margin-right: 15px;
}

.query-find-texts-text {
  font-size: 15px;
  font-weight: bold;
}

.tools-chips {
  width: 100% !important;
}

.query-chip-button {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #9C27B0;
  background-color: #9C27B0;
  transition: 0.3s;
}

.query-chip-button::after {
  font-family: "Material Icons";
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25px;
  line-height: 40px;
  content: '\e0ee';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #9C27B0;
  opacity: 1;
  transition: 0.3s;
}

.query-chip-button:hover::after {
  opacity: 0;
  display: none;
  transition: 0.3s;
}

.query-chip-button:hover {
  position: relative;
  width: 260px;
  height: auto;
  padding: 10px;
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #9C27B0;
  background-color: white;
  transition: 0.3s;
}

.query-chip-header {
  width: 260px;
}

.number-avatar {
  background-color: #9C27B0;
  color: white !important;
  padding: 0 8px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
}

.result-table {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.query-table-row {
  background-color: white;
}

.query-table-row:nth-child(even) {
  background-color: #EBECF0;
}

.checkbox-row {
  width: 4em;
}

.clickable-row {
  cursor: pointer;
}

.select-all-button {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
  font-weight: bold !important;
  border-radius: 15px !important;
}

.metainfo-subtitle {
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 0.75em;
}

.save-texts-button {
  margin-left: 1em !important;
}

.accordionBorder {
  border-radius: 0 0 4px 4px !important;
}

.openedResultsAccordion {
  margin-bottom: 1em !important;
}

.query-download-button-span {
  margin-bottom: 1em;
  float: right;
  margin-top: 0.7em;
}

.query-download-modal-button {
  height: 2.6em;
}

.query-download-dialog {
  width: 300px;
}

.query-download-modal-radio-group {
  margin-top: 1em !important;
  margin-left: 0.25em !important;
}

.tool-page-container-outer {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 700px;
  background-color: white;
  border-radius: 45px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.tools-tab-overlay {
  position: relative;
}

.tools-tab-overlay::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, 1);
  z-index: 2;
}

.outer-container-tools {
  height: auto;
  background: fixed linear-gradient(315deg, rgba(156, 39, 176, 1) 0%, rgba(156, 39, 176, 1) 33%, rgba(204, 168, 253, 1) 33%, rgba(204, 168, 253, 1) 67%, rgba(255, 208, 253, 1) 67%, rgba(255, 208, 253, 1) 100%);
  border-radius: 50px;
  padding: 7px;
  margin-bottom: 50px;
}

.query-container {
  height: auto;
  z-index: 10;
}

.outer-outer {
  width: auto;
  z-index: 10;
}

.tools-box-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  width: 100%;
  height: auto;
}

.alert-max-width {
  max-width: 700px;
}

.cluster-tools-container {
  height: auto;
  width: 1000px;
  display: flex;
  justify-content: center;
}

.tool-card-container {
  width: 600px;
  height: 200px;
  margin: auto;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  border: solid 2px rgba(156, 39, 176, 1);
}

.tool-card-icon {
  padding: 10px;
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-card-text {
  padding: 10px;
  height: 100%;
  width: 60%;
  display: flex;
  align-items: center;
  font-size: 1.2em
}

.tool-icon-img {
  height: 80%;
}

.tool-title {
  text-align: center;
  padding-bottom: 2vh;
}

.tool-wrapper {
  margin-top: 5vh;
}

.tool-accordion {
  display: flex;
  justify-content: space-between;
}

.correction-container-outer {
  border-radius: 20px;
}

.correction-container {
  background: white;
  border-radius: 15px;
  padding: 1.5em 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.gap-1 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.gap-2 {
  grid-gap: 1rem;
  gap: 1rem;
}

.corrector-button {
  width: 100px;
}

.text-span {
  position: relative;
  display: inline-block;
}

.corrector-right-inner {
  height: 100%;
  padding-top: 7rem;
}

.punctuation {
  min-width: 0.3rem;
  background-color: hsl(102.13 37% 75%);
  padding: 0.1rem 0.2rem 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: double 2px hsl(102.13 37% 55%);
}

.punctuation-hover {
  background-color: hsl(102.13 37% 55%);
}

.spelling-error {
  background-color: hsl(0 66% 76%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: dashed 2px hsl(0 66% 60%);
  white-space: nowrap;
}

.spelling-error-hover {
  background-color: hsl(0 66% 60%);
}

.word-count-error {
  background-color: hsl(44.71 100% 80%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: dotted 2px hsl(44.71 100% 60%);
  white-space: nowrap;
}

.word-count-error-hover {
  background-color: hsl(44.71 100% 60%);
}

.multiple-errors {
  background-color: hsl(31, 89%, 69%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: dotted 2px hsl(31, 89%, 49%);
  white-space: nowrap;
}

.multiple-errors-hover {
  background-color: hsl(31, 89%, 49%);
}

.missing-word-error,
.extra-word-error {
  background-color: hsl(255, 37%, 73%);
  padding: 0.1em 0.2em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: dotted 2px hsl(255, 37%, 63%);
  white-space: nowrap;
}

.missing-word-error-hover,
.extra-word-error-hover {
  background-color: hsl(255, 37%, 63%);
}

.text-span[data-color="gray"] {
  background-color: hsl(0, 0%, 80%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: dotted 2px hsl(0, 0%, 60%)
}

.text-span[data-color="gray"]:hover {
  background-color: hsl(0, 0%, 60%);
}

.word-order-error {
  background-color: hsl(217.5 78% 90%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: solid 2px hsl(217.5 78% 70%);
  white-space: nowrap;
}

.word-order-error-hover {
  background-color: hsl(217.5 78% 70%);
}

.text-span.spellingErrorhovering {
  background-color: hsl(0 66% 60%);
}

.text-span.wordOrderErrorhovering {
  background-color: hsl(217.5 78% 70%);
}

.text-span.wordCountErrorhovering {
  background-color: hsl(44.71 100% 60%);
}

.text-span.multipleErrorshovering {
  background-color: hsl(31, 89%, 49%);
}

.text-span.missingWordErrorhovering,
.text-span.extraWordErrorhovering {
  background-color: hsl(255, 37%, 63%);
}

.text-span[data-color="punctuation"] {
  min-width: 0.3rem;
  background-color: hsl(102.13 37% 75%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: double 2px hsl(102.13 37% 55%);
}

.text-span[data-color="punctuation"]:hover {
  background-color: hsl(102.13 37% 55%);
}

.text-span.missingPunctuationhovering,
.text-span.extraPunctuationhovering,
.text-span.wrongPunctuationhovering {
  background-color: hsl(102.13 37% 55%);
}

.corrector-right {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  background-color: whitesmoke;
  padding: 1em;
  border-radius: 5px;
  height: auto;
  min-height: 200px;
  min-width: 300px;
}

.corector-input {
  flex: 1 1;
  min-width: 300px;
}

.corrector-error-word {
  color: gray;
  -webkit-text-decoration: line-through gray;
          text-decoration: line-through gray;
  font-weight: bold;
}

.corrector-border-box {
  box-sizing: border-box;
}

.corrector-max-width {
  min-width: 500px;
}

.correction-single-error {
  display: flex;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  padding: 0.5em;
  transition: 0.3s;
}

.correction-single-error:hover {
  border-radius: 5px;
  background-color: hsl(217.5 78% 98%);
}

.corrector-error-icon-button {
  width: 1em;
  height: 1em;
}

.corrector-btn-visible {
  padding-top: 1em;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  transition: 0.3s;
}

.corrector-btn-invisible {
  display: none;
  grid-gap: 1em;
  gap: 1em;
  transition: 0.3s;
}

.mouseaway-listener {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  display: none;
}

.word-choice-error {
  background-color: hsl(190 25% 76%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: dashed 2px hsl(190 25% 60%);
  white-space: nowrap;
}

.word-choice-error-hover {
  background-color: hsl(190 25% 60%);
}

.case-error {
  background-color: hsl(315 33% 76%);
  padding: 0.1em 0.1em 0;
  border-radius: 2px 2px 1px 1px;
  border-bottom: dashed 2px hsl(315 33% 60%);
  white-space: nowrap;
}

.case-error-hover {
  background-color: hsl(315 33% 60%);
}

.tab-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 0 0.5em;
  margin-bottom: 0.5em;
}

.slider-track {
  width: 80%;
  background: rgb(193, 211, 254);
  background: linear-gradient(270deg, rgba(193, 211, 254, 1) 0%, rgba(237, 242, 251, 1) 30%, rgba(237, 242, 251, 1) 70%, rgba(193, 211, 254, 1) 100%);
  border-radius: 3px;
  position: relative;
}

.slider-track-complexity {
  width: 80%;
  background: rgb(193, 211, 254);
  background: linear-gradient(90deg, rgba(237, 242, 251, 1) 0, rgba(193, 211, 254, 1) 100%);
  border-radius: 3px;
  position: relative;
}

.slider-thumb {
  position: absolute;
  width: 3%;
  height: 140%;
  border: 4px solid #274c77;
  border-radius: 3px;
}

.index-row {
  display: flex;
  flex-direction: column;
  grid-gap: 5rem;
  gap: 5rem;
}

.long-sentence-color {
  background-color: hsl(0 66% 76%);
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
}

.long-word-color {
  background-color: hsl(100 66% 76%);
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
}

.noun-color {
  background-color: hsl(200 66% 76%);
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
}

.complexity-tab-header {
  margin-bottom: 0.5em;
  font-size: 1.5em;
}

.slider-labels {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 120%;
}

.word-repetition-color {
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
}

.same-sentence-color {
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
  background: hsl(31, 89%, 69%);
}

.next-sentence-color {
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
  background: hsl(44.71 100% 80%);
}

.uncommon-word-color {
  background-color: hsl(100 66% 76%);
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
}

.abstract-word-color {
  background-color: hsl(200 66% 76%);
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
}

.content-word-color {
  background-color: hsl(300 66% 76%);
  padding: 0.1em;
  border-radius: 2px 2px 1px 1px;
}

.text-level-tab-color-circle {
  height: 15px;
  width: 15px;
  border-radius: 10px;
}

.level-tab-short-text-notice {
  font-size: 1.5rem;
  margin: auto auto;
}

.level-accordion-overall-value-container {
  background: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.level-accordion-overall-value-label {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.harid-image {
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 12.5%;
}

.cookie-snackbar {
  .MuiAlert-standardInfo {
    background-color: rgba(255, 208, 253, 1) !important;
    border-radius: 8px;
    box-shadow: 0 1px 4px #0003;
  }

  .cookie-alert {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .cookie-alert-text {
      font-size: 16px;
    }
  }

  button {
    margin-top: 0.5rem;
  }
}

.server-error-page {
  display: grid;
  justify-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  padding-top: 2rem;
}

.server-error-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.footer-container {
  width: 100%;
  height: auto;
  text-align: center;
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 35%, rgba(255, 208, 253, 1) 35%, rgba(255, 208, 253, 1) 55%, rgba(255, 255, 255, 1) 55%, rgba(255, 255, 255, 1) 100%);
  margin-top: 0;
}

.footer-inner {
  width: 100%;
  padding-left: 6rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-gap: 4rem;
  gap: 4rem;
}

.footer-inner-right {
  display: flex;
  flex: 3 1;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-gap: 4rem;
  gap: 4rem;
}

.footer-logo-box {
  flex: 2 1;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.dti-logo {
  height: 8rem;
  margin-bottom: 2rem;
}

.footer-middle-box {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
}

.footer-box-right {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: start;
  padding-right: 4rem;
}

.footer-version {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  color: #0009;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.footer-version.no-link {
  text-decoration: none;
}

@media (max-width: 1024px) {
  .footer-inner {
    padding-left: 2rem;
    padding-top: 1rem;
  }

  .footer-inner-right {
    flex-direction: column;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .footer-logo-box {
    padding-left: 0;
    align-items: center;
  }

  .footer-box-right {
    padding-right: 0;
    justify-content: flex-start;
    align-items: center;
  }

  .footer-middle-box, .footer-box-right {
    align-items: center;
    text-align: center;
  }
}

.donate-text-button {
  cursor: pointer;
  width: 400px;
  border-radius: 20px !important;
  background-color: #9C27B0 !important;
  z-index: 100;
  height: 60px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding-left: 15px !important;
  position: fixed !important;
  right: -335px;
  top: 150px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  transition: 0.5s !important;
}

.donate-text-button:hover {
  right: -150px;
  transition: 0.5s !important;
}

.donate-text-font {
  font-weight: bold;
  font-size: 1rem;
}

.donate-text-icon {
  font-size: 40px !important;
  margin-right: 10px !important;
}

.session-expiration-modal {
  width: 30em !important;

  button:nth-of-type(2) {
    margin-left: 1rem;
  }
}

